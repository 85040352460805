import React from 'react'
import AlertMessageView from './View'
import { useAlertMessageStore } from 'services/alert-message'

export type AlertMessageProps = {}

const AlertMessage: React.FC<AlertMessageProps> = () => {
  const { isOpen, options, dismiss } = useAlertMessageStore()
  return (
    <AlertMessageView
      {...options}
      isOpen={isOpen}
      onClose={() => {
        dismiss()
        options.onClose && options.onClose()
      }}
    />
  )
}

export default AlertMessage
