import { useSignOut } from 'mutations/auth'
import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useAlertMessage } from 'services/alert-message'

export type SignOutScreenProps = RouteComponentProps

const SignOutScreen: React.FC<SignOutScreenProps> = ({ history, location }) => {
  const signOut = useSignOut()
  const alert = useAlertMessage()
  useEffect(() => {
    if (signOut.status === 'idle') {
      signOut.mutateAsync().then(() => {
        alert.show({
          message: 'Logged out successfully',
          severity: 'info',
        })
        history.replace({
          pathname: '/sign-in',
          state: location.state,
          search: location.search,
        })
      })
    }
  }, [signOut, history, location, alert])
  return <></>
}

export default SignOutScreen
