import React, { useEffect } from 'react'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router'
import { useAlertMessage } from 'services/alert-message'
import AuthScreenWithAvatarTemplate from 'components/templates/AuthScreenWithAvatarTemplate'

import SignInForm from 'components/molecules/SignInForm'

interface RouteState {
  from?: string
}

export type SignInScreenProps = RouteComponentProps<
  {},
  StaticContext,
  RouteState
>

const SignInScreen: React.FC<SignInScreenProps> = ({ location, history }) => {
  const alert = useAlertMessage()

  useEffect(() => {
    document.title = 'Sign In | Tiny Mile'
  }, [])

  const handleSubmit = () => {
    history.push({
      pathname: '/signed-in',
      search: location.search,
      state: location.state,
    })
  }

  const handleSubmitError = (err: { message: string }) =>
    alert.show({
      severity: 'error',
      message: err.message,
    })

  return (
    <AuthScreenWithAvatarTemplate title="Sign In" icon={<LockOutlinedIcon />}>
      <SignInForm
        onSubmissionError={handleSubmitError}
        onSubmit={handleSubmit}
      />
    </AuthScreenWithAvatarTemplate>
  )
}

export default SignInScreen
