import React from 'react'

import Appsignal from '@appsignal/javascript'
import { ErrorBoundary as AppsignalErrorBoundary } from '@appsignal/react'

const appsignal = new Appsignal({
  key: process.env['REACT_APP_SIGNAL_API_KEY'],
})

export interface ErrorBoundaryProps {}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => (
  <AppsignalErrorBoundary
    instance={appsignal}
    fallback={() => <>Uh oh... Something went wrong</>}
  >
    {children}
  </AppsignalErrorBoundary>
)
export default ErrorBoundary
