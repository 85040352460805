import React, { useEffect, useCallback } from 'react'
import SimpleBackdrop from 'components/molecules/SimpleBackdrop'
import AuthScreenWithAvatarTemplate from 'components/templates/AuthScreenWithAvatarTemplate'
import ErrorIcon from '@material-ui/icons/Error'
import { useRefreshAccessToken } from 'mutations/auth'
import { useUser } from 'queries/auth'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import { useAlertMessage } from 'services/alert-message'
import { useIdentityStore } from 'services/identity'

export type SignInCallbackScreenProps = RouteComponentProps

const SignInCallbackScreen: React.FC<SignInCallbackScreenProps> = () => {
  const alert = useAlertMessage()
  const redirectURL = useRedirectURL()
  const accessToken = useIdentityStore((s) => s.accessToken)
  const refreshAccessToken = useRefreshAccessTokenAndRedirectToApp()
  const user = useUser(accessToken)

  useEffect(() => {
    document.title = 'Checking... | Tiny Mile'
  }, [])

  useEffect(() => {
    if (user.data && redirectURL) {
      alert.show({
        message: `Welcome, ${user.data.first_name}! Redirecting you back in 3 seconds`,
        severity: 'success',
        autoCloseAfterMillis: 3000,
        onClose: () => {
          window.location.href = redirectURL
        },
      })
    }
  }, [user.data, alert, redirectURL])

  useEffect(() => {
    if (redirectURL && (!accessToken || user.isError)) {
      refreshAccessToken()
    }
  }, [redirectURL, accessToken, refreshAccessToken, user.data, user.isError])

  if (!redirectURL) {
    return (
      <AuthScreenWithAvatarTemplate
        title="Something went wrong..."
        icon={<ErrorIcon />}
      >
        We couldn't find the application you're trying to access.
      </AuthScreenWithAvatarTemplate>
    )
  } else {
    return <SimpleBackdrop />
  }
}

const useRedirectURL = () => {
  const location = useLocation()
  const redirectURL = new URLSearchParams(location.search).get('redirect')
  if (!redirectURL || !isValidRedirectURL(redirectURL)) {
    return null
  } else {
    return redirectURL
  }
}
const isValidRedirectURL = (url: string): boolean => {
  return redirectURLHostnameRegExp.test(new URL(url).hostname)
}

const redirectURLHostnameRegExp = /^(localhost|(.+\.)?tinymile\.ai)$/

const useRefreshAccessTokenAndRedirectToApp = () => {
  const location = useLocation()
  const history = useHistory()
  const alert = useAlertMessage()
  const refreshAccessToken = useRefreshAccessToken()

  return useCallback(() => {
    if (refreshAccessToken.isIdle && !refreshAccessToken.isSuccess) {
      refreshAccessToken.mutateAsync().catch(({ message }) => {
        alert.show({
          message,
          severity: 'info',
          autoCloseAfterMillis: 3000,
        })

        history.push({
          pathname: '/sign-in',
          state: location.state,
          search: location.search,
        })
      })
    }
  }, [alert, history, location.search, location.state, refreshAccessToken])
}

export default SignInCallbackScreen
