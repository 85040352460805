import React from 'react'

import { AxiosError } from 'axios'
import { Formik, Form } from 'formik'
import StartResetPasswordFormSchema from './Schema'
import StartResetPasswordFormData, {
  initialStartResetPasswordFormData,
} from './Model'
import { useStartResetPassword } from 'mutations/auth'

export interface StartResetPasswordFormikProps {
  onSubmit?: () => void
  onSubmissionError?: (error: { message: string }) => void
}

const StartResetPasswordFormik: React.FC<StartResetPasswordFormikProps> = ({
  onSubmit = () => {},
  onSubmissionError = () => {},
  children,
}) => {
  const startResetPassword = useStartResetPassword()
  return (
    <Formik
      validationSchema={StartResetPasswordFormSchema}
      initialValues={initialStartResetPasswordFormData}
      onSubmit={(v: StartResetPasswordFormData, { setSubmitting }) => {
        startResetPassword
          .mutateAsync(v)
          .then(() => {
            setSubmitting(false)
            onSubmit()
          })
          .catch((err: AxiosError<{ message: string }>) => {
            setSubmitting(false)
            onSubmissionError(
              err.response?.data || { message: 'Unknown Error' }
            )
          })
      }}
    >
      <Form>{children}</Form>
    </Formik>
  )
}

export default StartResetPasswordFormik
