import { useMutation } from 'react-query'
import Credentials from 'types/auth/Credentials'
import { UserEmail } from 'types/auth/User'
import { UserPassword } from 'types/auth/User'
import useAxios from 'services/axios/useAxios'
import User from 'types/auth/User'
import { useIdentityStore } from 'services/identity'

export const useSignIn = () => {
  const axios = useAxios()
  const identity = useIdentityStore()
  return useMutation(
    (credentials: Credentials) =>
      axios
        .post<{ access_token: string; user: User }>('/api/sign-in', credentials)
        .then((res) => res.data),
    {
      mutationKey: 'auth/sign-in',
      onSuccess: (data) => {
        identity.setIdentity(data.user, data.access_token)
      },
    }
  )
}

export const useStartResetPassword = () => {
  const axios = useAxios()
  return useMutation((userEmail: UserEmail) =>
    axios.post('/api/password-reset-flow/start', userEmail)
  )
}

export const useResetPassword = (authToken: string) => {
  const axios = useAxios()
  return useMutation((userPassword: UserPassword) =>
    axios.post('/api/password-reset-flow/complete', userPassword, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
  )
}

export const useRefreshAccessToken = () => {
  const axios = useAxios()
  const identity = useIdentityStore()
  return useMutation(
    () =>
      axios
        .post<{ access_token: string; user: User }>(
          '/api/access-token',
          {},
          { withCredentials: true }
        )
        .then((res) => res.data),
    {
      mutationKey: 'auth/refresh-access-token',
      onSuccess: (data) => {
        identity.setIdentity(data.user, data.access_token)
      },
    }
  )
}

export const useSignOut = () => {
  const axios = useAxios()
  const identity = useIdentityStore()
  return useMutation(
    () => axios.post<{ access_token: string; user: User }>('/api/sign-out', {}),
    {
      mutationKey: 'auth/sign-in',
      onSuccess: () => {
        identity.clearIdentity()
      },
    }
  )
}
