import React from 'react'

import Button, { ButtonProps } from '@material-ui/core/Button'
import { useFormikContext } from 'formik'

export type FormikSubmitButtonProps = ButtonProps

const FormikSubmitButton: React.FC<FormikSubmitButtonProps> = (props) => {
  const formik = useFormikContext()
  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      {...props}
      disabled={formik.isSubmitting}
    >
      {props.children ? props.children : 'Submit'}
    </Button>
  )
}

export default FormikSubmitButton
