import React, { useEffect } from 'react'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { useAlertMessage } from 'services/alert-message'
import AuthScreenWithAvatarTemplate from 'components/templates/AuthScreenWithAvatarTemplate'
import useAuthToken from 'services/auth/useAuthToken'

import ResetPasswordForm from 'components/molecules/ResetPasswordForm'

export interface ResetPasswordScreenProps {}

const ResetPasswordScreen: React.FC<ResetPasswordScreenProps> = () => {
  const authToken = useAuthToken()
  const alert = useAlertMessage()

  useEffect(() => {
    document.title = 'Reset Password | Tiny Mile'
  }, [])

  const handleSubmit = () => {
    alert.show({
      severity: 'info',
      message:
        'Your password is successfully updated. You can close this window now.',
    })
  }

  const handleSubmitError = (err: { message: string }) =>
    alert.show({
      severity: 'error',
      message: err.message,
    })

  return (
    <AuthScreenWithAvatarTemplate
      title="Reset Password"
      icon={<LockOutlinedIcon />}
    >
      {authToken && (
        <ResetPasswordForm
          authToken={authToken}
          onSubmissionError={handleSubmitError}
          onSubmit={handleSubmit}
        />
      )}
      {!authToken && <h3>Not Authorized</h3>}
    </AuthScreenWithAvatarTemplate>
  )
}

export default ResetPasswordScreen
