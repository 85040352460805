let defaultAppEnv: string

switch (process.env.NODE_ENV) {
  case 'production':
    defaultAppEnv = 'prod'
    break
  case 'development':
    defaultAppEnv = 'dev'
    break
  default:
    defaultAppEnv = process.env.NODE_ENV
}

export const APP_ENV = process.env.REACT_APP_ENV || defaultAppEnv

export const ROOT_DOMAIN =
  process.env.REACT_APP_ROOT_DOMAIN || `${APP_ENV}.gcp.tinymile.ai`

export const AUTH_SERVICE_URL =
  process.env['REACT_APP_AUTH_URL'] || `https://auth.${ROOT_DOMAIN}`
