import React from 'react'
import Grid from '@material-ui/core/Grid'
import FormikTextField from 'components/atoms/FormikTextField'
import FormikSubmitButton from 'components/atoms/FormikSubmitButton'
import ResetPasswordFormik from './Formik'

export interface ResetPasswordFormProps {
  authToken: string
  onSubmit?: () => void
  onSubmissionError?: (err: { message: string }) => void
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  authToken,
  onSubmit = () => {},
  onSubmissionError = () => {},
}) => {
  return (
    <ResetPasswordFormik
      authToken={authToken}
      onSubmit={onSubmit}
      onSubmissionError={onSubmissionError}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item container direction="column" spacing={3}>
          <Grid item>
            <FormikTextField
              name="password"
              type="password"
              variant="outlined"
              required
              fullWidth
              label="Password"
              autoFocus
            />
          </Grid>
          <Grid item>
            <FormikTextField
              name="confirmPassword"
              type="password"
              variant="outlined"
              required
              fullWidth
              label="Confirm Password"
            />
          </Grid>
        </Grid>
        <Grid item>
          <FormikSubmitButton fullWidth>Change Password</FormikSubmitButton>
        </Grid>
      </Grid>
    </ResetPasswordFormik>
  )
}

export default ResetPasswordForm
