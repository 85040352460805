import React from 'react'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Copyright from 'components/atoms/Copyright'
import { useStyles } from './styles'

export type AuthScreenTemplateProps = {}

const AuthScreenTemplate: React.FC<AuthScreenTemplateProps> = ({
  children,
}) => {
  const cls = useStyles()

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={cls.paper} variant="outlined">
        <Box padding={4}>{children}</Box>
      </Paper>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}

export default AuthScreenTemplate
