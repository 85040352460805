import { UseQueryOptions, useQuery } from 'react-query'
import useAxios from 'services/axios/useAxios'

import User from 'types/auth/User'
import { AxiosError } from 'axios'

export const useUser = (
  accessToken: null | string,
  queryOptions?: UseQueryOptions<User, AxiosError>
) => {
  const axios = useAxios()
  return useQuery(
    ['auth/user'],
    () =>
      axios
        .get('/api/users/me', {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => res.data),
    {
      ...queryOptions,
      enabled: Boolean(accessToken),
    }
  )
}
