import React from 'react'

import { AxiosError } from 'axios'
import { Formik, Form } from 'formik'
import SignInFormSchema from './Schema'
import SignInFormData, { initialSignInFormData } from './Model'
import { useSignIn } from 'mutations/auth'

export interface SignInFormikProps {
  onSubmit?: () => void
  onSubmissionError?: (error: { message: string }) => void
}

const SignInFormik: React.FC<SignInFormikProps> = ({
  onSubmit = () => {},
  onSubmissionError = () => {},
  children,
}) => {
  const signedIn = useSignIn()
  return (
    <Formik
      validationSchema={SignInFormSchema}
      initialValues={initialSignInFormData}
      onSubmit={(v: SignInFormData, { setSubmitting }) => {
        signedIn
          .mutateAsync(v)
          .then(() => {
            setSubmitting(false)
            onSubmit()
          })
          .catch((err: AxiosError<{ message: string }>) => {
            setSubmitting(false)
            onSubmissionError(
              err.response?.data || { message: 'Unknown Error' }
            )
          })
      }}
    >
      <Form>{children}</Form>
    </Formik>
  )
}

export default SignInFormik
