import create from 'zustand'
import * as R from 'ramda'
import shallow from 'zustand/shallow'

import AlertOptions from 'types/ui/AlertOptions'

export type AlertMessageState = {
  options: AlertOptions
  isOpen: boolean
  dismissed: boolean
}

export type AlertMessageAPI = {
  show: (options: AlertOptions) => void
  reopen: () => void
  dismiss: () => void
}

export const useAlertMessageStore = create<AlertMessageState & AlertMessageAPI>(
  (set) => ({
    options: {
      message: '',
      showLoading: false,
    },
    isOpen: false,
    dismissed: false,
    show: (options: AlertOptions) => set({ options, isOpen: true }),
    reopen: () => set({ isOpen: true }),
    dismiss: () => set({ isOpen: false }),
  })
)

export const useAlertMessage = (): AlertMessageAPI =>
  useAlertMessageStore(R.pick(['show', 'reopen', 'dismiss']), shallow)
