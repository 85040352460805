import React from 'react'
import { Route, Switch } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { BrowserRouter } from 'react-router-dom'
import SignInScreen from 'components/pages/SignInScreen'
import StartResetPasswordScreen from 'components/pages/StartResetPasswordScreen'
import ResetPasswordScreen from 'components/pages/ResetPasswordScreen'
import ReactQueryProvider from 'components/atoms/ReactQueryProvider'
import AlertMessage from 'components/organisms/AlertMessage'
import theme from './theme'
import SignInCallbackScreen from 'components/pages/SignInCallbackScreen'
import SignOutScreen from 'components/pages/SignOutScreen'
import ErrorBoundary from './ErrorBoundary'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary>
        <BrowserRouter>
          <ReactQueryProvider>
            <section className="containers">
              <Switch>
                <Route path="/sign-in" component={SignInScreen} />
                <Route path="/sign-out" component={SignOutScreen} />
                <Route
                  path="/start-reset-password"
                  component={StartResetPasswordScreen}
                />
                <Route path="/reset-password" component={ResetPasswordScreen} />
                <Route path="/" component={SignInCallbackScreen} />
              </Switch>
              <AlertMessage />
            </section>
          </ReactQueryProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
