import User from 'types/auth/User'
import create from 'zustand'

export type IdentityState = {
  accessToken: null | string
  user: null | User
}

export type IdentityAPI = {
  setIdentity: (user: User, accessToken: string) => void
  clearIdentity: () => void
}

export const useIdentityStore = create<IdentityState & IdentityAPI>((set) => ({
  accessToken: null,
  user: null,
  setIdentity: (user, accessToken) => set({ user, accessToken }),
  clearIdentity: () => set({ user: null, accessToken: null }),
}))
