import { createContext } from 'react'
import axios, { AxiosError, AxiosInstance } from 'axios'
import { AUTH_SERVICE_URL } from 'config/api'
import * as R from 'ramda'

interface CreateInstanceOpts {
  timeoutMs?: number
  withCredentials?: boolean
}

const createInstance = ({
  timeoutMs = 3000,
  withCredentials = false,
}: CreateInstanceOpts = {}) => {
  const instance = axios.create({
    baseURL: AUTH_SERVICE_URL,
    timeout: timeoutMs,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: withCredentials,
  })
  instance.interceptors.response.use(
    (req) => req,
    (err: AxiosError) => {
      if (err.response?.data && err.response.data.message) {
        const newErr = R.clone(err)
        newErr.message = err.response.data.message
        throw newErr
      } else {
        throw err
      }
    }
  )

  return instance
}

const AxiosContext = createContext<AxiosInstance>(
  createInstance({ withCredentials: true })
)

export default AxiosContext
