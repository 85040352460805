import React from 'react'

import { AxiosError } from 'axios'
import { Formik, Form } from 'formik'
import ResetPasswordFormSchema from './Schema'
import ResetPasswordFormData, { initialResetPasswordFormData } from './Model'
import { useResetPassword } from 'mutations/auth'

export interface ResetPasswordFormikProps {
  authToken: string
  onSubmit?: () => void
  onSubmissionError?: (error: { message: string }) => void
}

const ResetPasswordFormik: React.FC<ResetPasswordFormikProps> = ({
  authToken,
  onSubmit = () => {},
  onSubmissionError = () => {},
  children,
}) => {
  const resetPassword = useResetPassword(authToken)
  return (
    <Formik
      validationSchema={ResetPasswordFormSchema}
      initialValues={initialResetPasswordFormData}
      onSubmit={(v: ResetPasswordFormData, { setSubmitting }) => {
        resetPassword
          .mutateAsync({ password: v.password })
          .then(() => {
            setSubmitting(false)
            onSubmit()
          })
          .catch((err: AxiosError<{ message: string }>) => {
            setSubmitting(false)
            onSubmissionError(
              err.response?.data || { message: 'Unknown Error' }
            )
          })
      }}
    >
      <Form>{children}</Form>
    </Formik>
  )
}

export default ResetPasswordFormik
