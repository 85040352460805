import React from 'react'

import { Snackbar, Slide } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import AlertOptions from 'types/ui/AlertOptions'

export interface AlertMessageViewProps extends AlertOptions {
  isOpen?: boolean
}

const AlertMessageView: React.FC<AlertMessageViewProps> = ({
  message,
  onClose,
  severity = 'error',
  autoCloseAfterMillis = 5000,
  isOpen = false,
}) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoCloseAfterMillis}
      onClose={onClose}
      TransitionComponent={(props) => <Slide {...props} direction="down" />}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    >
      <Alert severity={severity} onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default AlertMessageView
