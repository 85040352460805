import { createMuiTheme } from '@material-ui/core/styles'
import tinymilePink from 'components/atoms/colors/tinymile-pink'

const theme = createMuiTheme({
  palette: {
    primary: { main: tinymilePink.primary },
  },
})

export default theme
