import React from 'react'
import Grid from '@material-ui/core/Grid'
import FormikTextField from 'components/atoms/FormikTextField'
import FormikSubmitButton from 'components/atoms/FormikSubmitButton'
import SignInFormik from './Formik'
import { Link } from 'react-router-dom'

export interface SignInFormProps {
  onSubmit?: () => void
  onSubmissionError?: (err: { message: string }) => void
}

const SignInForm: React.FC<SignInFormProps> = ({
  onSubmit = () => {},
  onSubmissionError = () => {},
}) => {
  return (
    <SignInFormik onSubmit={onSubmit} onSubmissionError={onSubmissionError}>
      <Grid container direction="column" spacing={4}>
        <Grid item container direction="column" spacing={3}>
          <Grid item>
            <FormikTextField
              name="email"
              variant="outlined"
              required
              fullWidth
              label="Email"
              autoFocus
            />
          </Grid>
          <Grid item>
            <FormikTextField
              name="password"
              type="password"
              variant="outlined"
              required
              fullWidth
              label="Password"
            />
          </Grid>
        </Grid>
        <Grid item>
          <Link to={'/start-reset-password'}>Forgot Password</Link>
        </Grid>
        <Grid item>
          <FormikSubmitButton fullWidth>Sign In</FormikSubmitButton>
        </Grid>
      </Grid>
    </SignInFormik>
  )
}

export default SignInForm
