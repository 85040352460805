import React from 'react'
import AuthScreenTemplate from '../AuthScreenTemplate'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import tinymilePink from 'components/atoms/colors/tinymile-pink'

export interface AuthScreenWithAvatarTemplateProps {
  icon: React.ReactElement
  avatarBackgroundColor?: string
  title: string
}

const AuthScreenWithAvatarTemplate: React.FC<
  AuthScreenWithAvatarTemplateProps
> = ({
  icon,
  avatarBackgroundColor = tinymilePink.primary,
  children,
  title,
}) => {
  return (
    <AuthScreenTemplate>
      <Grid container direction="column" spacing={3}>
        <Grid item container alignItems="center" direction="column" spacing={1}>
          <Grid item>
            <Avatar style={{ backgroundColor: avatarBackgroundColor }}>
              {icon}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography component="h1" variant="h5">
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </AuthScreenTemplate>
  )
}

export default AuthScreenWithAvatarTemplate
