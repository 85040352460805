import React, { useEffect } from 'react'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { RouteComponentProps } from 'react-router-dom'
import { useAlertMessage } from 'services/alert-message'
import AuthScreenWithAvatarTemplate from 'components/templates/AuthScreenWithAvatarTemplate'

import StartResetPasswordForm from 'components/molecules/StartResetPasswordForm'

export type StartResetPasswordScreenProps = RouteComponentProps<{}>

const StartResetPasswordScreen: React.FC<
  StartResetPasswordScreenProps
> = () => {
  const alert = useAlertMessage()

  useEffect(() => {
    document.title = 'Reset Password | Tiny Mile'
  }, [])

  const handleSubmit = () => {
    alert.show({
      severity: 'info',
      message: 'check you email for reset password instructions.',
    })
  }

  const handleSubmitError = (err: { message: string }) =>
    alert.show({
      severity: 'error',
      message: err.message,
    })

  return (
    <AuthScreenWithAvatarTemplate
      title="Reset Password"
      icon={<LockOutlinedIcon />}
    >
      <StartResetPasswordForm
        onSubmissionError={handleSubmitError}
        onSubmit={handleSubmit}
      />
    </AuthScreenWithAvatarTemplate>
  )
}

export default StartResetPasswordScreen
